// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-case-studies-habito-tsx": () => import("./../../../src/pages/case-studies/habito.tsx" /* webpackChunkName: "component---src-pages-case-studies-habito-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-documents-tsx": () => import("./../../../src/pages/products/documents.tsx" /* webpackChunkName: "component---src-pages-products-documents-tsx" */),
  "component---src-pages-products-transactions-tsx": () => import("./../../../src/pages/products/transactions.tsx" /* webpackChunkName: "component---src-pages-products-transactions-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-raconteur-tsx": () => import("./../../../src/pages/raconteur.tsx" /* webpackChunkName: "component---src-pages-raconteur-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-use-cases-aml-tsx": () => import("./../../../src/pages/use-cases/aml.tsx" /* webpackChunkName: "component---src-pages-use-cases-aml-tsx" */),
  "component---src-pages-use-cases-bnpl-tsx": () => import("./../../../src/pages/use-cases/bnpl.tsx" /* webpackChunkName: "component---src-pages-use-cases-bnpl-tsx" */)
}

